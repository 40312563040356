import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import Header from "./../../components/Header/Index";
import SimpleSlider from "./../../components/carousel";
import Box from "@mui/material/Box";
import Footer from "../../components/Footer";
import CompanyNews from "../CompanyNews";
import SiteAlerts from "../../components/SiteAlerts";
import { getSiteAlerts } from "../../redux/actions/siteAlerts/siteAlerts";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import ImportantUpdates from "../ImportantUpdates";
import { getNotice } from "../../redux/actions/notice/notice";
import { fetchFavorites } from "../../redux/actions/graph/favorites";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken } from "../../helper/auth";
import Loader from "../../components/Loader";

export const noticeboardCategory = "Important Updates";

export default function Dashboard({ setSearchDialogState }) {
  const { classes } = styles();
  const [companyNews, setCompanyNews] = useState([]);
  const [importantUpdates, setImportantUpdates] = useState([]);
  const { oAuthToken, loading } = useSelector((state) => state.oAuthToken);
  const [alerts, setAlerts] = useState();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userData.userData.id);
  const { siteAlerts } = useSelector((state) => state.siteAlerts);
  const { notice } = useSelector((state) => state.notice);
  const navigate = useNavigate();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState(null);
  const [closedAlerts, setClosedAlerts] = useState([]);
  const siteAlertDivRef = React.useRef(null);
  const [topValue, setTopValue] = useState(0);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessToken();
        setAccessToken(token);
      } catch (error) {
        console.error("Error fetching access token:", error);
        // Handle error fetching access token
      }
    };

    fetchAccessToken();
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!oAuthToken) getAccessToken();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.delete("key"); // Remove the 'key' parameter
    navigate({ search: params.toString() }, { replace: true });
  }, [location.search, navigate]);

  useEffect(() => {
    if (userId) dispatch(fetchFavorites(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (oAuthToken === null || oAuthToken === undefined) {
      dispatch(fetchAndStoreToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (oAuthToken && !siteAlerts?.length) {
      dispatch(getSiteAlerts(localStorage.getItem("oAuthToken")));
    }
  }, [dispatch, oAuthToken]);

  useEffect(() => {
    if (oAuthToken && !notice?.length) {
      dispatch(getNotice(localStorage.getItem("oAuthToken")));
    }
  }, [dispatch, oAuthToken]);

  useEffect(() => {
    if (notice && notice.length > 0) {
      let companyNews = notice.filter(
        (el) => el.noticeboard_category !== noticeboardCategory
      );
      let importantUpdates = notice.filter(
        (el) => el.noticeboard_category === noticeboardCategory
      );
      setCompanyNews(companyNews);
      setImportantUpdates(importantUpdates);
    }
  }, [notice]);

  useEffect(() => {
    // Load closed alerts from session storage
    const storedClosedAlerts =
      JSON.parse(sessionStorage.getItem("closedAlerts")) || [];
    if (storedClosedAlerts) {
      setClosedAlerts(storedClosedAlerts);
    }
  }, []);

  useEffect(() => {
    // Save closed alerts to session storage
    sessionStorage.setItem("closedAlerts", JSON.stringify(closedAlerts));
  }, [closedAlerts]);

  useEffect(() => {
    // Update alerts when siteAlerts change, filtering out closed alerts
    if (siteAlerts && siteAlerts.length > 0) {
      const filteredAlerts = siteAlerts
        .filter((alert) => !closedAlerts.includes(alert.sitealert_weight))
        .map((alert) => ({
          ...alert,
          sitealert_weight: parseFloat(alert.sitealert_weight),
        }))
        .sort((a, b) => a.sitealert_weight - b.sitealert_weight);
      setAlerts(filteredAlerts);
    }
  }, [siteAlerts, closedAlerts]);

  const handleAlertClose = (id) => {
    // Close alert by adding its ID to closed alerts
    setClosedAlerts([...closedAlerts, id]);
  };

  useEffect(() => {
    const divElement = siteAlertDivRef.current;

    const updateHeight = () => {
      if (divElement) {
        setTopValue(divElement.getBoundingClientRect().height);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (divElement) {
      resizeObserver.observe(divElement);
    }

    // Initial measurement
    updateHeight();

    return () => {
      if (divElement) {
        resizeObserver.unobserve(divElement);
      }
    };
  }, [siteAlerts, closedAlerts]);

  const renderSiteAlerts = (el) => {
    const id = el.sitealert_weight;

    if (closedAlerts.includes(id)) {
      return null; // Do not render closed alerts
    }

    const type = el.sitealert_type;
    const title = el.sitealert_title;
    const description = el.sitealert_description;
    const closable = el.sitealert_closable;
    const weight = el.sitealert_weight;

    return (
      <SiteAlerts
        key={id}
        type={type}
        title={title}
        description={description}
        closable={closable}
        weight={weight}
        onClose={() => handleAlertClose(weight)}
      />
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="siteAlertContainer" ref={siteAlertDivRef}>
            {alerts && alerts.map((el, index) => renderSiteAlerts(el, index))}
          </div>
          <Header
            position={"absolute"}
            top={topValue + "px"}
            setSearchDialogState={setSearchDialogState}
          />
          <SimpleSlider />
          <Box className={classes.content}>
            <Grid container spacing={4}>
              <Grid item md={6} lg={6} xs={12}>
                <ImportantUpdates
                  importantUpdates={importantUpdates}
                  companyNews={companyNews}
                />
              </Grid>
              <Grid item md={6} lg={6} xs={12}>
                <CompanyNews
                  companyNews={companyNews}
                  importantUpdates={importantUpdates}
                />
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      )}
    </>
  );
}
