import React, { useEffect, useState } from "react";
import Header from "../Header/Index";
import Footer from "../Footer";
import slider2 from "../../assets/images/slider/slider2.png";
import { Box, Grid, useMediaQuery } from "@mui/material";
import AttachmentViewer from "../AttachmentViewer";
import RelatedContentSlider from "../RelatedContentSlider";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetailPageData } from "../../redux/actions/detailPage/detailPage";
import styles from "./styles";
import "./style.scss";
import VideoViewer from "../VideoViewer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from "../Loader";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { useTheme } from "@emotion/react";
import FavoriteIconButton from "../AppCard/FavoriteIconButton";
import {
  addFavoriteContents,
  fetchFavorites,
  removeFavoriteContents,
} from "../../redux/actions/graph/favorites";
import { getUserData } from "../../redux/actions/userData/userData";

const style = {
  content: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#183750",
    opacity: "0.85",
  },
};

const DetailPage = () => {
  const { classes } = styles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");
  const detail_page_api_path = window.location.pathname;
  const baseUrl = window.location.origin;
  const favoritesContents = useSelector((state) => state.getFavoritesContents);
  const userId = useSelector((state) => state.userData.userData.id);
  const [detailData, setDetailData] = useState(null);
  const theme = useTheme();
  const handleLinkClick = (e) => {
    e.preventDefault();
    let href = null;
    if (e.target.href) {
      href = e.target.getAttribute("href");
    } else if (e.target.parentElement.href) {
      href = e.target.parentElement.getAttribute("href");
    }
    if (!href) {
      e.preventDefault();
      return;
    }
    if (
      href.startsWith("http") ||
      href.startsWith("https") ||
      href.startsWith("www") ||
      href.startsWith("//")
    ) {
      window.open(href, "_blank");
    } else if (href.startsWith("mailto")) {
      window.location.href = href;
    } else {
      navigate(href, { replace: false });
    }
  };

  const title = detailData ? detailData.title : "";
  const summary = detailData ? detailData.summary : "";
  const description = detailData && detailData.description;
  const attachment =
    detailData && detailData.attachment ? detailData.attachment : null;
  const thumbnail =
    detailData && detailData.thumbnail ? detailData.thumbnail : null;
  const internal_video =
    detailData && detailData.internal_video ? detailData.internal_video : null;
  const external_video =
    detailData && detailData.external_video ? detailData.external_video : null;
  const external_Quicklinks =
    detailData && Array.isArray(detailData.quick_links)
      ? detailData.quick_links[0].external_links
      : null;
  const internal_Quicklinks =
    detailData && Array.isArray(detailData.quick_links)
      ? detailData.quick_links[0].internal_links
      : null;
  const related_contents =
    detailData && Array.isArray(detailData.related_contents)
      ? detailData.related_contents[0].related_contents
      : null;
  const descriptionBoxFlag =
    (!description ||
      description === "" ||
      description === '<div className="custom_editor"></div>') &&
      (attachment || thumbnail)
      ? true
      : false;
  const dispatch = useDispatch();
  const { detail, status, loading, error } = useSelector(
    (state) => state.detail
  );
  const { oAuthToken } = useSelector((state) => state.oAuthToken);

  useEffect(() => {
    if (oAuthToken === null || oAuthToken === undefined) {
      dispatch(fetchAndStoreToken());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(() => {
    if (oAuthToken && detail_page_api_path) {
      dispatch(
        getDetailPageData({
          detailAPI: detail_page_api_path,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    }
  }, [dispatch, oAuthToken, detail_page_api_path]);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [detail_page_api_path]);

  useEffect(() => {
    if (detail && detail.length > 0) {
      setDetailData(detail[0]);
    }
  }, [detail]);

  const isFavorite = (detail_page_api_path) => {
    // Iterate over each object in the getFavorites array
    if (
      null != favoritesContents &&
      favoritesContents.getFavoritesContents &&
      undefined != favoritesContents &&
      favoritesContents.getFavoritesContents
    ) {
      if (
        favoritesContents.getFavoritesContents.favoritesContents?.includes(
          detail_page_api_path + "=" + title
        )
      ) {
        return true; // AppId is found, so it's a favorite
      }
    }
    return false; // AppId is not found in any favorites
  };
  const handleFavoriteChangeInDetailpage = (
    detail_page_api_path,
    isFavorite
  ) => {
    if (!isFavorite) {
      dispatch(
        addFavoriteContents({
          userId,
          contentId: detail_page_api_path + "=" + title,
        })
      ).then((res) => {
        if (res.payload && !res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    } else {
      dispatch(
        removeFavoriteContents({
          userId,
          contentId: detail_page_api_path + "=" + title,
        })
      ).then((res) => {
        if (res.payload && !res.payload.error) {
          dispatch(fetchFavorites(userId));
        }
      });
    }
  };

  return (
    <>
      <Header />
      <Box
        className={classes.bannerimgBox}
        sx={{
          backgroundImage: `url(${slider2})`,
        }}
      >
        <Grid container spacing={4} style={style.content}>
          <Link style={{ zIndex: 1 }} to={-1}>
            <div className={classes.backArrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.1562H7.83L13.42 5.56625L12 4.15625L4 12.1562L12 20.1562L13.41 18.7463L7.83 13.1562H20V11.1562Z"
                  fill="#D0D0D2"
                />
              </svg>
              <div className={classes.backTxt}>Back</div>
            </div>
          </Link>
          <Grid item xs={6} xsOffset={1} className={classes.BannerItem1}>
            <h1 className={classes.BannerHeading}>{loading ? "" : title}</h1>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.BannerItem2}>{loading ? "" : summary}</div>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          height: "50px",
          backgroundColor: `${theme.palette.secondary.main}`,
          opacity: "0.9",
        }}
      >
        {isMobile ? (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: "0px" }}
          >
            <Grid item>
              {" "}
              {!loading && title && (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    padding: "5px",
                    marginLeft: "auto",
                    marginTop: "-6px",
                    marginRight: "0px",
                    backgroundColor: "#CDEBFF",
                    borderRadius: "50%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-3.5px",
                    }}
                  >
                    <FavoriteIconButton
                      aria-haspopup="true"
                      isFavorite={isFavorite(detail_page_api_path)} // Pass isFavorite as a prop
                      isDetail={true}
                      onClick={() =>
                        handleFavoriteChangeInDetailpage(
                          detail_page_api_path,
                          isFavorite(detail_page_api_path)
                        )
                      } // Pass the handler function for favorite click
                      detailPagePath={detail_page_api_path}
                    />
                  </div>
                </div>
              )}
            </Grid>
            {/* <Grid
            item
            xs={2}
            style={{ paddingTop: "9px", position: "relative", paddingLeft:"0px" }}
          >
            <DownloadForOfflineRoundedIcon
              variant="filled"
              sx={{
                color: `${theme.palette.secondary.main}`,
                backgroundColor: "#CDEBFF",
                padding: "5px",
                marginRight: "12px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
            <ShareRoundedIcon
              variant="filled"
              sx={{
                color: `${theme.palette.secondary.main}`,
                backgroundColor: "#CDEBFF",
                padding: "5px",
                marginRight: "12px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          </Grid> */}
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ marginTop: "0px" }}>
            <Grid item xs={12}>
              {" "}
              {!loading && title && (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    padding: "5px",
                    marginLeft: "auto",
                    marginTop: "-6px",
                    marginRight: "40px",
                    backgroundColor: "#CDEBFF",
                    borderRadius: "50%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "-5px",
                      top: "-3.5px",
                    }}
                  >
                    <FavoriteIconButton
                      aria-haspopup="true"
                      isFavorite={isFavorite(detail_page_api_path)} // Pass isFavorite as a prop
                      isDetail={true}
                      onClick={() =>
                        handleFavoriteChangeInDetailpage(
                          detail_page_api_path,
                          isFavorite(detail_page_api_path)
                        )
                      } // Pass the handler function for favorite click
                      detailPagePath={detail_page_api_path}
                    />
                  </div>
                </div>
              )}
            </Grid>
            {/* <Grid
            item
            xs={2}
            style={{ paddingTop: "9px", position: "relative", paddingLeft:"0px" }}
          >
            <DownloadForOfflineRoundedIcon
              variant="filled"
              sx={{
                color: `${theme.palette.secondary.main}`,
                backgroundColor: "#CDEBFF",
                padding: "5px",
                marginRight: "12px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
            <ShareRoundedIcon
              variant="filled"
              sx={{
                color: `${theme.palette.secondary.main}`,
                backgroundColor: "#CDEBFF",
                padding: "5px",
                marginRight: "12px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          </Grid> */}
          </Grid>
        )}
      </Box>
      {loading ? <Loader /> : ""}
      {Array.isArray(external_Quicklinks) ||
        Array.isArray(internal_Quicklinks) ? (
        <Box className={classes.quickLinksDynamicHtmlBox}>
          <Grid
            container
            xs={12}
            className={classes.quickLinksResponsiveContainer}
          >
            <Grid item xs={8} className={classes.quickLinksDynamicHtmlBoxGrid1}>
              <span
                onClick={(e) => {
                  // Only trigger on elements that are links and have a href attribute
                  if (
                    e.target.tagName === "A" &&
                    e.target.getAttribute("href")
                  ) {
                    handleLinkClick(e);
                  }
                  if (
                    e.target.tagName === "U" ||
                    e.target.tagName === "EM" ||
                    (e.target.tagName === "STRONG" &&
                      e.target.parentElement.href)
                  ) {
                    handleLinkClick(e);
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: loading ? "" : description ? description : "",
                }}
              ></span>
            </Grid>
            <Grid item xs={4} className={classes.quickLinksDynamicHtmlBoxGrid2}>
              <h1 className={classes.QuickLinksHeading}>Quick Links</h1>
              <div className={classes.QuickLinksUrlBox}>
                {!loading &&
                  external_Quicklinks &&
                  external_Quicklinks.map((link) => (
                    <div className={classes.QuickLinksUrl}>
                      {link.external_link.startsWith(baseUrl) ?
                        <Link
                          className={classes.QuickLinksUrlFont}
                          to={link.external_link.replace(baseUrl, '')}
                          state={link}
                        >
                          {link.external_title}
                        </Link> :
                        <a
                          className={classes.QuickLinksUrlFont}
                          target="_blank"
                          href={link.external_link}
                        >
                          {link.external_title}
                        </a>
                      }
                    </div>
                  ))}
                {!loading &&
                  internal_Quicklinks &&
                  internal_Quicklinks.map((link) => (
                    <div className={classes.QuickLinksUrl}>
                      <Link
                        className={classes.QuickLinksUrlFont}
                        to={link.detail_page_api_link}
                        state={link}
                      >
                        {link.content_title}
                      </Link>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          className={
            descriptionBoxFlag
              ? classes.dynamicHtmlBox2
              : classes.dynamicHtmlBox
          }
        >
          <span
            onClick={(e) => {
              // Only trigger on elements that are links and have a href attribute
              if (e.target.tagName === "A" && e.target.href !== "") {
                handleLinkClick(e);
              }
              if (
                e.target.tagName === "U" ||
                e.target.tagName === "EM" ||
                (e.target.tagName === "STRONG" && e.target.parentElement.href)
              ) {
                handleLinkClick(e);
              }
            }}
            dangerouslySetInnerHTML={{
              __html: loading ? "" : description ? description : "",
            }}
          ></span>
        </Box>
      )}
      {!loading && (attachment || thumbnail) && (
        <AttachmentViewer attachment={attachment} thumbnail={thumbnail} />
      )}
      {!loading && internal_video && <VideoViewer videoUrl={internal_video} />}
      {!loading && external_video && <VideoViewer videoUrl={external_video} />}
      {!loading && Array.isArray(related_contents) && (
        <Box className={classes.relatedContentBox}>
          <Grid container spacing={2} padding="10px">
            <Grid item xs={12}>
              <h1 className={classes.relatedContentHeading}>Related Content</h1>
            </Grid>
          </Grid>
          <RelatedContentSlider relatedContents={related_contents} />
        </Box>
      )}
      <Footer id="footer-detail" />
    </>
  );
};

export default DetailPage;
