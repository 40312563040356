import React from "react";
import { Card, CardContent, Box, useMediaQuery } from "@mui/material";
import styles from "./styles";
import { msalConfig } from "./../../helper/auth";
import FavoriteIconButton from "./FavoriteIconButton";
import { Link } from "react-router-dom";
import CustomTooltip from "../Tooltip";

function AppCard(props) {
  const {
    app,
    contentId,
    isFavorite,
    onFavoriteChange,
    handleCardClick,
    isDashboard,
  } = props;
  const { classes } = styles();
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleFavoriteClick = () => {
    onFavoriteChange(contentId || app.appId, isFavorite); // Notify the parent component of the favorite change
  };

  const contentLink = (contentId && contentId.split("=")[0]) || "";
  const contentTitle = (contentId && contentId.split("=")[1]) || "";
  const appDisplayName = app?.displayName || "";
  const appId = app?.appId || "";
  const appDescription = app?.description || "";

  const contentTitleElement = (
    <Link
      to={contentLink}
      style={{
        textDecoration: "none",
        width: "100%",
        alignContent: "center",
      }}
    >
      <div
        style={{
          fontFamily: "Montserrat-Regular",
          lineHeight: "16px",
          color: "#183750",
          fontWeight: "700",
          fontSize: "16px",
          overflow: "hidden", // Hide overflow text
          textOverflow: "ellipsis", // Show ellipsis for overflow
        }}
      >
        {contentTitle && contentTitle.length > 62
          ? contentTitle.slice(0, 60) + ".."
          : contentTitle}
      </div>
    </Link>
  );
  const appNameElement = (
    <a
      href={`https://launcher.myapps.microsoft.com/api/signin/${appId}?${msalConfig.auth.tenantId}`}
      target="_blank"
      style={{
        textDecoration: "none",
        width: "100%",
        alignContent: "center",
      }}
      rel="noreferrer"
    >
      <div
        style={{
          fontFamily: "Montserrat-Regular",
          lineHeight: "16px",
          color: "#183750",
          fontWeight: "700",
          fontSize: "16px",
          overflow: "hidden", // Hide overflow text
          textOverflow: "ellipsis", // Show ellipsis for overflow
        }}
      >
        {appDisplayName && appDisplayName.length > 62
          ? appDisplayName.slice(0, 60) + ".."
          : appDisplayName}
      </div>
    </a>
  );

  return (
    <Card
      className={
        isDashboard && isMobile
          ? classes.favoriteDashboardCardRoot
          : classes.favoriteCardRoot
      }
      onClick={handleCardClick}
    >
      <CardContent className={classes.cardContentPadding}>
        <Box className={classes.imgBox}>
          {contentLink ? (
            contentTitle && contentTitle.length > 62 ? (
              // <CustomTooltip title={contentTitle} placement="bottom-start">
              contentTitleElement
              // </CustomTooltip>
            ) : (
              contentTitleElement
            )
          ) : appDescription ? (
            // <CustomTooltip title={appDescription} placement="bottom-start">
            appNameElement
            // </CustomTooltip>
          ) : (
            appNameElement
          )}
          <div>
            <FavoriteIconButton
              isFavorite={isFavorite}
              isDashboard={isDashboard}
              onClick={handleFavoriteClick}
            />
          </div>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AppCard;
