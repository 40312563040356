import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        favContainer: {
            display: 'flex !important', height: 'auto', flexDirection: 'row', flexWrap: 'wrap', paddingTop: '15px'
        },
        favDashboardContainer: {
            display: 'flex !important', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '160px'
        },
        favMobileDashboardContainer: {
            display: 'flex !important', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '0px', justifyContent:'center'
        },
        favMobileDashboardContainerNoApps: {
            display: 'flex !important', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '40px',
        },
        favAppCardContainer: {
            display: "flex", flexWrap: "wrap",
        },
        favMobileAppCardContainer: {
            display: "flex", flexWrap: "wrap", justifyContent: 'center', alignItems: 'center'
        },
        backTxt: {
            fontFamily: "Montserrat-Regular",
            color: theme.palette.info.light,
            fontSize: "16px",
            fontWeight: "600",
            letterSpacing: "1px",
            paddingLeft: "8px",
        },
        backArrow: {
            display: "flex",
            position: "absolute",
            alignItems: "center",
            margin: "20px 0px 0px 20px"
        },
    }
}
);

export default styles;