import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme,{isDashboard}) => {
  return {
    root: {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
      },
      '& .MuiButtonBase-root': {
        color: theme.palette.info.main,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '20px',
        textTransform: 'capitalize',
        letterSpacing: '0.1px',
        fontFamily: "Montserrat-Regular !important",
      },
      '& .MuiTabs-indicator':{
        backgroundColor:isDashboard
        ? theme.palette.common.white
        : theme.palette.info.main
      }
    }
  }
}
);


export default styles;