import React from "react";
import { Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styles from "./styles";

function TabPanel({ tabs, value, handleChange, isDashboard }) {
  const theme = useTheme();
  const { classes } = styles({isDashboard});
  return (
    <Tabs
      className={classes.root}
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          value={tab.value}
          style={{
            color: isDashboard
              ? theme.palette.common.white
              : theme.palette.info.main,
          }}
        />
      ))}
    </Tabs>
  );
}

export default TabPanel;
